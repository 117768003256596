const loginPage = {
  'loginForm.account.tabText': 'Account Login',
  'loginForm.phone.tabText': 'Phone Login',
  'loginForm.用户名': 'Username',
  'loginForm.密码': 'Password',
  'loginForm.手机号': 'Phone Number',
  'loginForm.请输入验证码': 'Please enter captcha',
  'loginForm.获取验证码': 'Get Captcha',
  'loginForm.自动登录': 'Auto Login',
  'loginForm.注销': 'Logout',
}

const menu = {
  'menu.首页': 'Homepage',
  'menu.首页.统计资料': 'Homepage Statistic',
  'menu.首页.轮播图': 'Homepage Carousel Banners',
  'menu.首页.楼层管理': 'Homepage Floors',
  'menu.用户管理': 'Users',
  'menu.用户管理.注册用户': 'Registed Users',
  'menu.用户管理.用户徽章': 'User Badges',
  'menu.测试白名单': 'Test whitelist',
  'menu.产品分类': 'Product Category',
  'menu.产品分类.一级类目.二级类目': 'Secondary Category',
  'menu.产品分类.一级类目.分类产品列表': 'Product List',
  'menu.产品管理': 'Products',
  'menu.产品管理.购买链接': 'Purchase Location List',
  'menu.地点管理': 'Store Location',
  'menu.文章管理': 'Articles',
  'menu.文章管理.推荐产品': 'Recommended Products',
  'menu.礼遇专区': 'Coupons',
  'menu.数据分析': 'Analytics',
  'menu.短信': 'Sms',
  'menu.短信.短信模板': 'Sms Templates',
  'menu.短信.彩信模板': 'Mms Templates',
  'menu.短信.发送任务管理': 'Send Task List',
  'menu.短信.新建发送任务': 'New Send Task',
  'menu.评价管理': 'Reivews',
  'menu.徽章管理': 'Badges',
}

const common = {
  'common.新建': 'New ',
  'common.编辑': 'Edit ',
  'common.删除': 'Remove',
  'common.列表': ' List',
  'common.请输入': 'Please enter {name}',
  'common.请选择': 'Please select {name}',
  'common.提交成功': 'Change Successfully',
  'common.置顶': 'Pin',
  'common.取消置顶': 'Unpin',
  'common.导出数据': 'Export data',
  'common.导入数据': 'Import data',
  'common.已选': 'Selected',
  'common.项': 'Item',
  'common.取消选择': 'Deselect',
}

const tips = {
  'tips.确认要删除吗': 'Are you sure to delete this record?',
  'tips.确认要上架吗': 'Change status to active?',
  'tips.确认要下架吗': 'Change status to inactive?',
}

const page = {
  'page.轮播图': 'Banner',
  'page.描述': 'Keywords',
  'page.封面': 'Cover',
  'page.排序': 'Sort',
  'page.操作': 'Function',
  'page.已删除': 'Removed',

  'page.首页楼层': 'Home Floors',
  'page.楼层标题': 'Floor Title',
  'page.是否展示': 'Visible',
  'page.素材类型': 'Source Type',
  'page.素材': 'Source',
  'page.链接类型': 'Link Type',
  'page.应用ID': 'App ID',
  'page.跳转链接': 'Link Url',
  'page.标题': 'Title',
  'page.子标题': 'Sub Title',
  'page.显示logo': 'Show Logo',
  'page.显示遮罩效果': 'Show Mask',
  'page.显示按钮': 'Show Button',
  'page.按钮文字': 'Button Text',
  'page.楼层code': 'Floor Code',
  'page.备注': 'Remark',
  'page.可见性': 'Visibility',

  'page.用户': 'User',
  'page.下载数据': 'Download',
  'page.昵称': 'User name',
  'page.头像': 'Avatar',
  'page.性别': 'Gender',
  'page.手机号': 'Phone Number',
  'page.邮箱': 'Email',
  'page.推荐店铺': 'Referral store',
  'page.注册推荐人': 'Referral',
  'page.用户首次来源': 'First Origin',
  'page.通讯地址': 'Address',
  'page.创建时间': 'Created time',

  'page.类目': 'Category',
  'page.名称': 'Name',
  'page.二级类目': 'Secondary Category',
  'page.产品列表': 'Product List',
  'page.类目名称': 'Category Name',
  'page.类目封面': 'Category Cover',
  'page.状态': 'Status',

  'page.主图': 'Cover Image',
  'page.关联新的产品': 'Associate new product',

  'page.购买链接': 'Purchase Location',
  'page.新增商品': 'New Product',
  'page.商品列表': 'Product List',
  'page.基本信息': 'Base Info',
  'page.详细信息': 'Detail Info',
  'page.商品名称': 'Product Name',
  'page.商品描述': 'Product Description',
  'page.商品图片': 'Product Photoes',
  'page.详情内容': 'Details',
  'page.产品ID': 'Product ID',

  'page.新增购买地点': 'New Purchase Location',
  'page.编辑购买信息': 'Edit Purchase Location',
  'page.购买地点列表': 'Purchase Location List',
  'page.地点': 'Location',
  'page.购买地点': 'Purchase Location',
  'page.小程序购买路径': 'Mini Program Address',

  'page.地址': 'Address',
  'page.营业时间': 'Opening Hours',
  'page.营业日期': 'Opening Days',
  'page.地点封面': 'Location Cover',
  'page.微信小程序ID': 'MP APP ID',
  'page.小程序页面路径': 'MP Page Path',

  'page.券': 'Coupon',
  'page.券名称': 'Coupon Name',
  'page.活动店铺': 'Store Name',
  'page.有效期': 'Validity',
  'page.已领取': 'Received',
  'page.已核销': 'Used',
  'page.券类型': 'Coupon Type',
  'page.券描述': 'Coupon Description',
  'page.活动时间': 'Validity',
  'page.库存': 'Count',
  'page.兑换口令': 'Exchange password',
  'page.兑换口令6位': '6-digit exchange password',
  'page.券封面': 'Coupon Cover',
  'page.详情封面': 'Detail Cover',
  'page.领取地域限制': 'Selective exposure city',

  'page.文章': 'Article',
  'page.文章封面': 'Article Cover(single)',
  'page.推荐产品': 'Recommended Products',
  'page.产品名称': 'Product Name',
  'page.产品封面': 'Product Cover(single)',
  'page.板块': 'Classification',
  'page.事件名称': 'Event Name',
  'page.跳转方式': 'Jump Type',

  'page.上架': 'Active',
  'page.已上架': 'Active',
  'page.下架': 'Inactive',
  'page.失效': 'Expired',

  'page.操作人': 'Operator',

  'page.模板ID': 'Template ID',
  'page.签名': 'Signature',
  'page.正文': 'Content',
  'page.模板状态': 'Audit Status',
  'page.短信模板': 'Sms Template',
  'page.短信标题': 'Sms Title',
  'page.发送人群': 'Send People',
  'page.编号': 'No',
  'page.变量Key': 'Var Key',
  'page.变量Value': 'Var Value',
  'page.变量配置': 'Vars Config',
  'page.发送': 'Send',

  'page.彩信模板': 'Mms Template',
  'page.彩信标题': 'Mms Title',
  'page.图片': 'Image',
  'page.主题': 'Subject',
  'page.彩信文本': 'Mms Text',
  'page.彩信图片': 'Mms Image',
  'page.第N页配置': 'Page {index}',
  'page.发送模板': 'Send Template',
  'page.模板类型': 'Send Type',
  'page.模板标题': 'Tamplate Title',
  'page.人数': 'Count',
  'page.任务状态': 'Task Status',
  'page.发送任务': 'Send Task',

  'page.商品ID': 'Product ID',
  'page.评价标题': 'Review Title',
  'page.评价内容': 'Review Content',
  'page.评价': 'Review',

  'page.徽章': 'Badge',
  'page.徽章名称': 'Badge Name',
  'page.徽章描述': 'Badge Description',
  'page.徽章图片': 'Badge Image',

  'page.用户徽章': 'User Badges',
  'page.用户ID': 'User ID',
  'page.评价日期': 'Review Date',
  
  'page.下载日期范围': 'Download Date Range',
}

const analysis = {
  'analysis.人数统计': 'User statistics',
  'analysis.累积用户数': 'Total Unique Visitors',
  'analysis.累积访客数': 'Total Unique Visitors',
  'analysis.累积注册用户数': 'Total Registed Users',
  'analysis.昨日访问用户数': 'Number of Visitors',
  'analysis.昨日新增用户数': 'Number of New Visitors',
  'analysis.使用统计': 'Behavior statistics',
  'analysis.打开次数': 'Opening session',
  'analysis.访问次数': 'Number of visits',
  'analysis.人均停留时长': 'Average duration / user',
  'analysis.次均停留时长': 'Average duration / session',
  'analysis.平均访问深度': 'Average number of page / session',

  'analysis.tip.visit_total': 'The cumulative number of users accessing the applet in history. Multiple accesses by the same user are not counted repeatedly.',
  'analysis.tip.session_cnt': 'The total number of times the applet was opened. The process from opening the applet to actively closing or quitting the applet after timeout is counted as one time.',
  'analysis.tip.visit_pv': 'The total number of times the applet page was accessed. Jumps between multiple pages and repeated visits to the same page are counted as multiple visits.',
  'analysis.tip.stay_time_uv': 'The average total length of time each user stays on the applet page (in seconds), that is, the total length of stay / number of visitors.',
  'analysis.tip.stay_time_session': 'The average total time (in seconds) that an applet stays on the applet page each time it is opened, that is, the total dwell time / number of times it is opened.',
  'analysis.tip.visit_depth': 'The average number of duplicate removal pages accessed each time the applet is opened.',
}

export const antdPro = {
  'tableForm': {
    'search': 'Search',
  },
}

export default {
  ...loginPage,
  ...menu,
  ...common,
  ...page,
  ...tips,
  ...analysis,
}
